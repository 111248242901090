$(document).ready(function () {
	// filter
	$('.carsearch select').on('change', function () {
		var arr = [];
		$('.carsearch select').each(function (indx) {
			arr.push($(this).val())
		});
		if (arr[0] == null) {
			data = {path: '74'}
		} else {
			data = {path: arr[0]}
		}
		if (arr[1] != "") {
			data['filter_ocfilter'] = "m:" + arr[1];
		}
		if (arr[2] != "") {
			if (data['filter_ocfilter']) {
				data['filter_ocfilter'] = data['filter_ocfilter'] + ";30043:" + arr[2];
			} else {
				data['filter_ocfilter'] = "30043:" + arr[2];
			}
		}
		if (arr[3] != "") {
			if (data['filter_ocfilter']) {
				data['filter_ocfilter'] = data['filter_ocfilter'] + ";30041:" + arr[3];
			} else {
				data['filter_ocfilter'] = "30041:" + arr[3];
			}
		}
		$.get('index.php?route=extension/module/ocfilter/callback', data, function (json) {
			for (var i in json.values) {

				value = json.values[i];
				if (value.t == 0) {
					$('.carsearch option').each(function () {
						if ($(this).attr('data-opt-val') == i) {
							$(this).attr("disabled", "disabled");
						}
					})
				}
			}
			$('.carsearch a.btn').attr('href', json.href);
		});
	});
	// popup
	$('.feedback').on('click', function () {
		popupopen('Обратный звонок', 'У вас остались вопросы?<br/>Наш менеджер перезвонит вам всего через 30 сек.', 'feedback', 'Жду звонка')
	});
	$('.popup-close, .popup-overlay').on('click', function () {
		$('.popup,.popup-overlay').hide();
	});
	$(this).keydown(function (eventObject) {
		if (eventObject.which == 27)
			$('.popup,.popup-overlay').hide();
	});
	// mask
	$('input[name="phone"]').mask('(999) 999-9999');
	// product
	$('.product-price-choose .input-radio span').on('click', function () {
		$('.product-price-choose .input-radio span').each(function () {
			$(this).removeClass('active');
		});
		$(this).addClass('active');
		if ($(this).parent().hasClass('rent')) {
			$('.rent-conditions span').addClass('rent');
		} else {
			$('.rent-conditions span').removeClass('rent');
		}
	});
	$('.rent-conditions span').on('mouseenter', function () {
		$('.product-description-hide>div').hide();
		if ($('.rent-conditions').hasClass('description-price')) {
			$('.product-description-hide .product-description-price-text').show();
		} else if ($('.rent-conditions').hasClass('description-price-rent')) {
			$('.product-description-hide .product-description-price-rent-text').show();
		} else {
			if ($(this).hasClass('rent')) {
				$('.product-description-hide .product-description-price-rent-text').show();
			} else {
				$('.product-description-hide .product-description-price-text').show();
			}
		}
	});

	$(".product-nav").on("click", ".nav-tabs li", function () {
		var tabs = $(".product-nav .nav-tabs li"),
			cont = $(".product-nav .tab-content>div");
		tabs.removeClass("active");
		cont.removeClass("active");
		$(this).addClass("active");
		cont.eq($(this).index()).addClass("active");
		return false;
	});

	$('.choose-tarrif .input-radio span').on('click', function () {
		$('.choose-tarrif .input-radio span').each(function () {
			$(this).removeClass('active');
		});
		$(this).addClass('active');
	});

	$('.choose-tarrif .info').on('mouseenter mouseleave', function () {
		$(this).parent().children('.description-tarrif').slideToggle();
	});

	$(document).on('click', '#other_cars .pagination ul li a', function (e) {
		var product_id = $('#other_cars .pagination').attr('data-product-id');
		var url = $(this).attr('href') + '&limit=6' + '&filter_product_id=' + product_id;
		$.ajax({
			url: url,
			type: 'get',
			success: function (data) {
				var data_cat = $(data).find('.cat-grid .cat-item');
				var pagination = $(data).find('ul.pagination');
				$('#other_cars .pagination').html(pagination);
				data_cat.addClass('cat-item-releated');
				$('#other_cars .cat-grid').html(data_cat);

			}
		});
		return false;
	});

	// adaptiv
	if ($(document).width() < 768) {
		/*$('.timeworks').css({'margin-right': $(document).width() - $('.mainphone').offset().left - $('.mainphone').width() - 20});*/
		$('.main-banner').css({'height': $(document).width() * 354 / 320 + 'px'});
		$('.mobile_menu').on('click', function () {
			$('.nav-header').show();
		});
		$('.close-header-menu span').on('click', function (e) {
			$('.mobile_menu  .nav-header').slideUp();
		});
		$('.navbar-brand').on('click', function () {
			console.log(1);
			$('#ocfilter').slideToggle().toggleClass('active');
		})
	}
});




// calculator
$(document).ready(function () {
	$(".new-product-total-min-time").keyup(function () {
		calculatePrice();
	});
	// clicks positions
	$('.choose-tarrif .input-radio').on('click', function () {
		var tarrif = $(this).parent().attr('class');
		$('.product-total-price-overlay').attr('data-tarrif', tarrif);
		$.ajax({
			url: 'index.php?route=product/product/getPriceForProduct',
			type: 'post',
			data: 'product_id=' + $(this).attr('data-id') + '&price=' + $(this).attr('data-price'),
			success: function (json) {
				for (key in json.mas_tarrif) {
					if (key == tarrif) {
						if (key == 'aeroport') {
							$('.product-total-price-on-hour, .product-total-min-time,.product-total-mult,.product-total-min-time-text').hide();
							$('.product-total-price-summ').html(json.mas_tarrif[key].price_to_aeroport);
						} else {
							var min_hours = $.trim(json.mas_tarrif[key].min_time);
							var price = json.mas_tarrif[key].price * min_hours;
							$(".new-product-total-min-time").data('min', min_hours);
							$('.product-total-price-on-hour,.product-total-min-time,.product-total-mult,.product-total-min-time-text').show();
							$('.product-total-price-on-hour .price').html(json.mas_tarrif[key].price);
							$('.product-total-min-time .hours').html(min_hours);
							$('.new-product-total-min-time').val(min_hours);
							$('.product-total-price-summ').html(price);
						}
					}
				}
			}
		})
	});
	calculatePrice();
	$(".new-product-total-min-time").on('click', clearData);
	$('.btn-product-order, .btn-product').on('click', function (e) {
		popupopen('Сделать заказ', '', 'order-product-calc', 'Заказать', this);
	});
});

function clearData() {
	$(".new-product-total-min-time").val('');
	$(".product-total-price-summ").text('');
}

/**
 * Функция рассчета стоимости по параметрам
 */
function calculatePrice() {
	var summ = $(".product-total-price-summ");
	var price_on_hour = parseInt($(".product-total-price-on-hour .price").text());
	var time = getHoursfromCalc();
	var total = price_on_hour * time;
	summ.html(total);
}

function getHoursfromCalc() {
	var min_time = parseInt($(".new-product-total-min-time").data('min'));
	var time = parseInt($(".new-product-total-min-time").val());
	if (time < min_time) {
		time = min_time;
	}
	return time;
}

function getPrice() {
	return $('.product-total-price-summ').text();
}

// popup windows order
/*function popupopen(title, smalltitle, type, btn, el) {
	var popup = $('.popup');
	popup.css({'top': $(document).scrollTop() + 'px'});

	popup.children('.popup-title').html(title);
	popup.children('.popup-small-title').html(smalltitle);
	if (type == 'feedback') {
		popup.find('.no-feed').hide();
	} else {
		popup.find('.no-feed').show();
	}

	if (type == 'order') {
		car_name = $(el).parent().parent().children('.cat-item-name').text();
		popup.children('.popup-title').html(title + ' ' + car_name);
		$('.popup-content input[name="car"]').val($(el).attr('data-product-id'));
		console.log('order');
	}

	if (type == 'order-product-calc') {
		console.log('order-product-calc');
		car_name = $("h1").text();
		popup.children('.popup-title').html(title + ' ' + car_name);
		var tarrif = $('.product-total-price-overlay').attr('data-tarrif');
		mas_tarrif = {};
		mas_tarrif["base"] = 'Деловая поездка';
		mas_tarrif["wedding"] = 'Свадебный';
		mas_tarrif["aeroport"] = 'Аэропорт';
		var my_tariff = mas_tarrif[tarrif];
		if (tarrif == 'aeroport') {
			var my_time = '';
		} else {
			var my_time = 'на ' + getHoursfromCalc() + 'ч.';
		}
		var my_price = getPrice();
		var zakaz = 'Заказ "' + car_name + '" по тарифу "' + my_tariff + '" ' + my_time + ' Стоимостью ' + my_price + ' р.';
		//var zakaz = 'Заказ "' + car_name + '"';
		$('.popup-content textarea').val(zakaz);
		$('.popup-content input[name="car"]').val($(el).attr('data-product-id'));
	}
	popup.find('.btn').val(btn);
	popup.show();
	$('.popup-overlay').show();
}*/

/**
 * validate numbers
 * @param evt
 */
function validate(evt) {
	var theEvent = evt || window.event;
	var key = theEvent.keyCode || theEvent.which;
	key = String.fromCharCode(key);
	var regex = /[0-9]|\./;
	if (!regex.test(key)) {
		theEvent.returnValue = false;
		if (theEvent.preventDefault) theEvent.preventDefault();
	}
}


$(document).ready(function() {
	// filter
	$('.carsearch select').on('change',function(){
		var arr=[];
		$('.carsearch select').each(function(indx){
			arr.push($(this).val())
		});
		if(arr[0]==null){
			data={path:'74'}	
		}else{
			data={path:arr[0]}
		}
		if(arr[1]!=""){	
			data['filter_ocfilter']="m:"+arr[1];
		}
		if(arr[2]!=""){
			if(data['filter_ocfilter'])	{
				data['filter_ocfilter']=data['filter_ocfilter']+";30043:"+arr[2];
			}else{
				data['filter_ocfilter']="30043:"+arr[2];
			}	
		}	
		if(arr[3]!=""){
			if(data['filter_ocfilter'])	{
				data['filter_ocfilter']=data['filter_ocfilter']+";30041:"+arr[3];
			}else{
				data['filter_ocfilter']="30041:"+arr[3];
			}	
		}
		$.get('index.php?route=extension/module/ocfilter/callback', data, function(json) {			
			for(var i in json.values){

				value=json.values[i];
				if(value.t==0){
					$('.carsearch option').each(function(){
						if($(this).attr('data-opt-val')==i){
							$(this).attr("disabled","disabled");
						}
					})
				}
			}
			$('.carsearch a.btn').attr('href', json.href);
		});
	});
	// popup
	$('.feedback').on('click',function(){popupopen('Обратный звонок','У вас остались вопросы?<br/>Наш менеджер перезвонит вам всего через 30 сек.','feedback','Жду звонка')})
	$('.popup-close, .popup-overlay').on('click',function(){$('.popup,.popup-overlay').hide();})
	$(this).keydown(function(eventObject){
        if (eventObject.which == 27)
            $('.popup,.popup-overlay').hide();
    });
    // mask
    $('input[name="phone"]').mask('(999) 999-9999');
    // product
    $('.product-price-choose .input-radio span').on('click',function(){
    	$('.product-price-choose .input-radio span').each(function(){
    		$(this).removeClass('active');
    	});
    	$(this).addClass('active');
		if($(this).parent().hasClass('rent')){
			$('.rent-conditions span').addClass('rent');
		}else{
			$('.rent-conditions span').removeClass('rent');
		}   	
    })
    $('.rent-conditions span').on('mouseenter',function(){
    	$('.product-description-hide>div').hide();
    	if($('.rent-conditions').hasClass('description-price')){
    		$('.product-description-hide .product-description-price-text').show();
    	}else if($('.rent-conditions').hasClass('description-price-rent')){
    		$('.product-description-hide .product-description-price-rent-text').show();
    	}else{
    		if($(this).hasClass('rent')){
    			$('.product-description-hide .product-description-price-rent-text').show();
    		}else{
    			$('.product-description-hide .product-description-price-text').show();
    		}
    	}    	  	
    })

	$(".product-nav").on("click", ".nav-tabs li", function(){
		var tabs = $(".product-nav .nav-tabs li"),
		    cont = $(".product-nav .tab-content>div");
		tabs.removeClass("active");
		cont.removeClass("active");
		$(this).addClass("active");
		cont.eq($(this).index()).addClass("active");
		return false;
	});

	$('.choose-tarrif .input-radio span').on('click',function(){
    	$('.choose-tarrif .input-radio span').each(function(){
    		$(this).removeClass('active');
    	});
    	$(this).addClass('active');   	
    })

    $('.choose-tarrif .info').on('mouseenter mouseleave',function(){
    	$(this).parent().children('.description-tarrif').slideToggle();
    });

    $('.choose-tarrif .input-radio').on('click',function(){
    	var tarrif=$(this).parent().attr('class');
    	$('.product-total-price-overlay').attr('data-tarrif',tarrif);
    	$.ajax({
    		url: 'index.php?route=product/product/getPriceForProduct',
			type: 'post',
			data: 'product_id='+$(this).attr('data-id')+'&price='+$(this).attr('data-price'),			
			success: function(json){
				for (key in json.mas_tarrif) {
					if(key==tarrif){
						if(key=='aeroport'){
							$('.product-total-price-on-hour, .product-total-min-time,.product-total-mult,.product-total-min-time-text').hide();
							$('.product-total-price').html(json.mas_tarrif[key].price_to_aeroport);
						}else{
							$('.product-total-price-on-hour, .product-total-min-time,.product-total-mult,.product-total-min-time-text').show();
							$('.product-total-price-on-hour').html(json.mas_tarrif[key].price);
							$('.product-total-min-time').html(json.mas_tarrif[key].min_time);
							$('.product-total-price').html(json.mas_tarrif[key].min_price);
						}
					}
				}
			}
    	})
    })
    $('.price_change_tariff').on('click',function(){
    	if($(this).hasClass('input')){
    		var val=$('.new-product-total-min-time').attr('data-val');
    		var min=$('.new-product-total-min-time').attr('data-min');
    		if(+val<+min){val=min;}
    		$('.product-calculate .product-total-min-time').html(val);    		
    		var price=$('.product-total-price-on-hour').text().replace("р","").replace(" ","");
    		price=+price;
    		val=+val;
    		var total=price*val;
    		$('.product-total-price').html(+total+' р.');
    		$(this).removeClass('input');
    	}else{
	    	var val=$('.product-total-min-time').text();
	    	$(this).addClass('input');
	    	$('.product-calculate .product-total-min-time').html('<input class="new-product-total-min-time" data-min='+val+' type="text" onkeypress="inputkeypress(this,event)" placeholder="'+val+' value=""/>');
	    }
    })
    $(document).on('click','#other_cars .pagination ul li a',function(e){
    	var product_id=$('#other_cars .pagination').attr('data-product-id');
    	var url=$(this).attr('href')+'&limit=6'+'&filter_product_id='+product_id;
    	$.ajax({
    		url: url,
			type: 'get',			
			success: function(data){
				var data_cat=$(data).find('.cat-grid .cat-item');
				var pagination=$(data).find('ul.pagination');
				$('#other_cars .pagination').html(pagination);
				data_cat.addClass('cat-item-releated');
				$('#other_cars .cat-grid').html(data_cat);

			}
    	})
    	return false;
    });
    $('.btn-product-order').on('click',function(e){
    	popupopen('Сделать заказ','','order-product-calc','Заказать',this);
    })
});

function popupopen(title,smalltitle,type,btn,el){
	var popup=$('.popup');
	popup.css({'top':$(document).scrollTop()+'px'})
	
	popup.children('.popup-title').html(title);
	popup.children('.popup-small-title').html(smalltitle);
	if(type=='feedback'){
		popup.find('.no-feed').hide();
	}else{
		popup.find('.no-feed').show();
	}

	if(type=='order'){
		car_name=$(el).parent().parent().children('.cat-item-name').text();
		popup.children('.popup-title').html(title + ' ' + car_name);
		$('.popup-content input[name="car"]').val($(el).attr('data-product-id'));	
	}
	
	if(type=='order-product-calc'){
		var car_name = $("h1").text();;
		popup.children('.popup-title').html(title + ' ' + car_name);
		var tarrif=$('.product-total-price-overlay').attr('data-tarrif');
		mas_tarrif={};
		mas_tarrif["base"]='Деловая поездка';
		mas_tarrif["wedding"]='Свадебный';
		mas_tarrif["aeroport"]='Аэропорт';
		var zakaz='Заказ '+car_name; //по тарифу "'+mas_tarrif[tarrif]+ '" на '+$('.product-total-min-time').text()+'ч. Стоимостью '+ $('.product-total-price').text();
		$('.popup-content textarea').val(zakaz);
		$('.popup-content input[name="car"]').val($(el).attr('data-product-id'));
	}
	popup.find('.btn').val(btn);
	popup.show();
	$('.popup-overlay').show();
}

function inputkeypress(input,event){	
	var ch=getChar(event);
	var value=input.getAttribute('data-val')?input.getAttribute('data-val'):'';
	var rep = /[0-9]/; 
    if (rep.test(ch)) {            
       value=value+ch;
       input.setAttribute('data-val',value);
    }
}

function getChar(event) {
  if (event.which == null) {
    if (event.keyCode < 32) return null;
    return String.fromCharCode(event.keyCode) // IE
  }

  if (event.which != 0 && event.charCode != 0) {
    if (event.which < 32) return null;
    return String.fromCharCode(event.which) // остальные
  }

  return null; // специальная клавиша
}